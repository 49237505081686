<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    :persistent="load"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title>
        {{ p_nueva ? 'Nueva cuota' : (editar ? 'Editar cuota' : 'Detalle cuota') }}
        <v-spacer></v-spacer>
        <template v-if="!p_nueva && cuota.inhabilitada != 1">
          <v-switch
            v-model="editar"
            label="Editar"
            class="d-flex align-top mt-0"
            hide-details
            dense
          ></v-switch>
          <v-divider
            class="mx-3"
            vertical
          ></v-divider>
        </template>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8">
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="cuota.empresa_codigo"
                label="Empresa"
                item-text="nombre_corto"
                item-value="id"
                :items="empresas"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                :autofocus="editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="cuota.tarjeta_codigo"
                label="Tarjeta"
                item-text="nombre"
                item-value="codigo"
                :items="p_tarjetas"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
             <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="cuota.nombre"
                label="Nombre"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="3" class="py-0">
              <v-text-field
                v-model="cuota.cuotas"
                label="Cuotas"
                type="number"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="3" class="py-0">
              <v-text-field
                v-model="cuota.cuota_interna"
                :readonly="!editar"
                :filled="!editar"
                label="Cuota interna"
                hint="**"
                type="number"
                persistent-hint
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="cuota.plan"
                label="Plan de cuotas"
                :readonly="!editar"
                :filled="!editar"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col v-if="!p_nueva" cols="6" md="3" class="py-0">
              <v-switch
                v-model="cuota.inhabilitada"
                label="Inhabilitada"
                class="d-flex align-top mt-0"
                :true-value="1"
                :false-value="null"
                :readonly="!editar"
                hide-details
                dense
              ></v-switch>
            </v-col>
            <v-col cols="10" :md="p_nueva ? 5 : 11" class="py-0">
              <v-autocomplete
                v-model="bancos"
                label="Bancos"
                item-text="nombre"
                item-value="id"
                :items="bancos_pais.filter(bp => bp.inhabilitado != 1 || bancos.filter(ba => ba == bp.id).length > 0)"
                :readonly="!editar"
                :filled="!editar"
                :clearable="editar"
                deletable-chips
                small-chips
                multiple
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" md="1" class="py-0">
              <v-btn
                :disabled="p_nueva? false : !editar"
                color="success"
                x-small
                fab
                title="Agregar Banco Pais"
                @click="openModalBancosPais()"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model="cuota.observaciones"
                label="Observaciones"
                rows="3"
                :readonly="!editar"
                :filled="!editar"
                hide-details
                auto-grow
                outlined
                dense
              ></v-textarea>
            </v-col>
            <v-col cols="12" class="pb-0 pt-2">
              <span class="caption">
                ** Ahora 12 = 7, Ahora 18 = 8, Ahora 24 = 25, Ahora 3 = 13 y Ahora 6 = 16 (no aplica para AMEX)
              </span>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-card flat outlined>
                <v-card-title class="body-1 pt-2 pb-0">
                  Proyecciones
                  <v-spacer></v-spacer>
                  <v-checkbox
                    v-model="cuota.en_el_dia"
                    label="En el día"
                    class="pb-1"
                    :true-value="1"
                    :false-value="null"
                    :readonly="!editar"
                    hide-details
                    dense
                    @change="cuota.dias_pago = null; cuota.periodicidad_pago = null; cuota.dias_corte = null; cuota.periodicidad_corte = null"
                  ></v-checkbox>
                </v-card-title>
                <v-card-text class="pt-6">
                  <v-row>
                    <v-col cols="5" md="2" class="py-0">
                      <v-text-field
                        v-model="cuota.dias_pago"
                        label="Días pago"
                        :rules="editar && cuota.en_el_dia != 1 ? [rules.required] : []"
                        :readonly="!editar || cuota.en_el_dia == 1"
                        :filled="!editar || cuota.en_el_dia == 1"
                        validate-on-blur
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="7" md="4" class="py-0">
                      <v-autocomplete
                        v-model="cuota.periodicidad_pago"
                        label="Periodicidad pago"
                        :items="periodicidad_pago"
                        :rules="editar && cuota.en_el_dia != 1 ? [rules.required] : []"
                        :readonly="!editar || cuota.en_el_dia == 1"
                        :filled="!editar || cuota.en_el_dia == 1"
                        validate-on-blur
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="5" md="2" class="py-0">
                      <v-text-field
                        v-model="cuota.dias_corte"
                        label="Días corte"
                        :rules="editar && cuota.en_el_dia != 1 && cuota.periodicidad_corte != 'DIARIO' ? [rules.required] : []"
                        :readonly="!editar || cuota.en_el_dia == 1 || cuota.periodicidad_corte == 'DIARIO'"
                        :filled="!editar || cuota.en_el_dia == 1 || cuota.periodicidad_corte == 'DIARIO'"
                        validate-on-blur
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="7" md="4" class="py-0">
                      <v-autocomplete
                        v-model="cuota.periodicidad_corte"
                        label="Periodicidad corte"
                        :items="periodicidad_corte"
                        :rules="editar && cuota.en_el_dia != 1 ? [rules.required] : []"
                        :readonly="!editar || cuota.en_el_dia == 1"
                        :filled="!editar || cuota.en_el_dia == 1"
                        validate-on-blur
                        outlined
                        dense
                        @change="cuota.periodicidad_corte == 'DIARIO' ? cuota.dias_corte = null : ''"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <!-- 24-04-2024 por MMURILLO, fecha desde, forma pago, tipo y formato de cheque -->
                  <v-row>
                    <v-col cols="12" md="4" sm="4" class="py-0">
                      <FechaPicker
                        v-model="cuota.vigencia_desde"
                        label="Vigencia Desde"
                        :disabled="!editar || cuota.en_el_dia == 1"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col cols="12" md="4" sm="4" class="py-0">
                      <FechaPicker
                        v-model="cuota.vigencia_hasta"
                        label="Vigencia Hasta"
                        :disabled="!editar || cuota.en_el_dia == 1 || cadenaVacia(cuota.vigencia_desde)"
                      />
                    </v-col>
                    <v-col cols="12" md="4" sm="4" class="py-0">
                      <v-autocomplete
                        v-model="cuota.forma_pago"
                        label="Forma Pago"
                        :items="banco_tipos_mov"
                        item-value="tipo_mov_codigo"
                        item-text="tipo_mov_nombre"
                        validate-on-blur
                        outlined
                        dense
                        :rules="[rules.required]"
                        :readonly="!editar || cuota.en_el_dia == 1"
                        :filled="!editar || cuota.en_el_dia == 1"
                        @change="cuota.tipo_cheque = null; cuota.formato_cheque = null"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3" sm="3" class="py-0" v-if="cuota.forma_pago == 3">
                      <v-autocomplete
                        v-model="cuota.tipo_cheque"
                        label="Tipo Cheque"
                        :items="tipos_cheque"
                        item-value="id"
                        item-text="nombre"
                        validate-on-blur
                        outlined
                        dense
                        :rules="cuota.forma_pago == 3 ? [rules.required] : []"
                        :readonly="!editar || cuota.en_el_dia == 1"
                        :filled="!editar || cuota.en_el_dia == 1"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3" sm="3" class="py-0" v-if="cuota.forma_pago == 3">
                      <v-autocomplete
                        v-model="cuota.formato_cheque"
                        label="Formato"
                        :items="formatos_cheque"
                        item-value="id"
                        item-text="nombre"
                        validate-on-blur
                        outlined
                        dense
                        :rules="cuota.forma_pago == 3 ? [rules.required] : []"
                        :readonly="!editar || cuota.en_el_dia == 1"
                        :filled="!editar || cuota.en_el_dia == 1"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          :color="editar ? 'error' : 'secondary'"
          :disabled="load"
          @click="dialog = false"
        >
          {{ editar ? 'Cancelar' : 'Volver' }}
        </v-btn>
        <BtnConfirmar
          v-if="editar"
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
      <ModalNvoEditarBancoPaisVue 
        v-model="objModal.activo"
        :datos="objModal"
        @guardarCambios="nvoBancoAgregado"
      />
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para editar o crear una cuota
 * 
 *  Notas: este componente emite acciones (@nueva, @editar) al padre caundo se crea o edita una cuota (en caso de estar definidas)
 *         todos los props son obligatorios
 */
import { mapState, mapGetters } from 'vuex'
import { get_nombre_obj_arr, cadenaVacia, order_list_by } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar'
import FechaPicker from '../util/FechaPicker.vue'
import moment from 'moment'
import ModalNvoEditarBancoPaisVue from './ModalNvoEditarBancoPais.vue'

export default {
  data () {
    return {
      load: false,
      editar: false,
      bancos: [],
      bancos_pais: [],
      periodicidad_pago: [
        'DE CADA MES',
        'DÍAS CORRIDOS',
        'DÍAS HÁBILES'
      ],
      periodicidad_corte: [
        'DE CADA MES',
        'DIARIO',
        'DÍAS'
      ],
      cuota: {},
      rules: {
        required: value => !!value || 'Campo requerido',
      },
      cadenaVacia: cadenaVacia,
      objModal: {
        activo: false,
        nuevo: false,
        banco: {
          id_banco: 0,
          banco_nombre: null,
          inhabilitado: 0,
        },
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    let result = await this.$store.dispatch('bancos/getAllBancosPais')
    this.$store.state.loading = false

    if (result.exito == 1) {
      this.bancos_pais = result.data
    } else {
      this.$store.dispatch('show_snackbar', {
        text: result.message,
        color: 'error'
      })
    }
  },
  props: {
    value: Boolean,
    p_nueva: Boolean,  // para editar una cuota enviar en false, para una cuota nueva en true
    p_cuota: Object,   // en caso de ser nueva enviar el objeto vacio {}
    p_tarjetas: Array, // array de tarjetas habilitadas
  },
  computed: {
    ...mapGetters(['una_empresa']),
    ...mapState(['empresas']),
    ...mapState('cheques', ['tipos_cheque', 'formatos_cheque', 'banco_tipos_mov']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    FechaPicker,
    ModalNvoEditarBancoPaisVue
  },
  watch: {
    dialog (val) {
      if (val) {
        if (this.p_nueva) {
          this.editar = true
          // si es nueva setea los campos en null
          this.bancos = []
          this.cuota = {
            nombre: null,
            empresa_codigo: null,
            tarjeta_codigo: null,
            cuotas: null,
            cuota_interna: null,
            plan: null,
            observaciones: null,
            vigencia_desde: moment(new Date()).format('DD/MM/YYYY'),
            vigencia_hasta: null,
            forma_pago: null,
            tipo_cheque: null,
            formato_cheque: null
          }
        } else {
          this.editar = false
          // crea una copia del prop p_cuota para editarlo
          this.setCuenta()
        }
      } else {
        this.$refs.form.resetValidation()
      }
    },
    editar (val) {
      if (!val) {
        // cuando cancela la edicion vuelve a dejar la cuota como estaba originalmente
        this.setCuenta()
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.load = true
        if (this.p_nueva) {
          let datos = JSON.parse(JSON.stringify(this.cuota))
          datos.bancos = this.bancos
          await this.$store.dispatch('cuotas/nueva', datos)
            .then((res) => {
              this.dialog = false
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success',
              })
              // emite la accion 'nueva' definifa en el padre
              this.$emit('nueva', res.cuota)
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        } else {
          // solo edita si realmente se modifico la cuota
          if (JSON.stringify(this.cuota) != JSON.stringify(this.p_cuota) || JSON.stringify(this.cuota.bancos) != JSON.stringify(this.bancos)) {
            let datos = JSON.parse(JSON.stringify(this.cuota))
            // armo mi array de bancos
            let bancos = []
            let eliminados = this.cuota.bancos.filter(bp => !this.bancos.includes(bp))
            let nuevos = this.bancos.filter(bp => !this.cuota.bancos.includes(bp))
            for (const id of eliminados) {
              bancos.push({
                id: id,
                accion: 'DELETE'
              })
            }
            for (const id of nuevos) {
              bancos.push({
                id: id,
                accion: 'INSERT'
              })
            }
            datos.bancos = bancos
            
            await this.$store.dispatch('cuotas/editar', datos)
              .then((res) => {
                this.dialog = false
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success',
                })
                // hace un UPPER del nombre, el plan y las observaciones de la cuota
                this.cuota.nombre = this.cuota.nombre.toUpperCase()
                if (this.cuota.plan) this.cuota.plan = this.cuota.plan.toUpperCase()
                if (this.cuota.observaciones) this.cuota.observaciones = this.cuota.observaciones.toUpperCase()
                // obtiene el nombre de la tarjeta y la empresa
                this.cuota.empresa_nombre = get_nombre_obj_arr(this.empresas, 'id', this.cuota.empresa_codigo, 'nombre_corto')
                this.cuota.tarjeta_nombre = get_nombre_obj_arr(this.p_tarjetas, 'codigo', this.cuota.tarjeta_codigo)
                // emite la accion 'editar' definifa en el padre
                this.$emit('editar', this.cuota, this.bancos)
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          } else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: `No se realizaron cambios en ${this.cuota.nombre} de ${this.cuota.tarjeta_nombre}: los nuevos valores son iguales a los anteriores`,
              color: 'warning',
              timeout: 4000
            })
          }
        }
        this.load = false
      }
    },
    setCuenta () {
      this.cuota = JSON.parse(JSON.stringify(this.p_cuota))
      this.bancos = [...this.cuota.bancos]
    },
    openModalBancosPais(){
      let bancoAux = {
        id_banco: 0,
        banco_nombre: null,
        inhabilitado: 0,
      };
      this.objModal.banco = bancoAux;
      this.objModal.nuevo = true;
      this.objModal.activo = true;
    },
    async nvoBancoAgregado(item, nuevo){
      item.inhabilitado = item.inhabilitado == null ? 0 : item.inhabilitado;

      this.$store.state.loading = true;
      const res = await this.$store.dispatch('bancos/nuevoBanco', item)
      this.$store.state.loading = false;

      if(res.resultado == 0){ return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', }); }
      
      this.$swal.fire({
        icon: 'success',
        title:'Banco creado',
        text: 'Se creo el banco con éxito',
        timer: 3000
      });
      
      item.id_banco = res.id_banco;
      item.banco_nombre = item.banco_nombre.toUpperCase();
      
      this.bancos_pais.push({ nombre: item.banco_nombre, id: item.id_banco, inhabilitado: item.inhabilitado })
      order_list_by(this.bancos_pais, 'nombre')
      
      this.bancos.push(item.id_banco)

      this.objModal.activo = false;
    }
  }
}
</script>