<template>
  <v-dialog
    v-model="dialog"
    max-width="700px"
    :persistent="load"
    scrollable
  >
    <v-card>
      <v-card-title>
        Financiaciones
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-0 px-5">
        <v-data-table
          class="cebra"
          :headers="headers"
          :items="financiaciones"
          :loading="load"
          dense
        >
          <template v-slot:[`item.alicuota`]="{ item }">
            {{ item.alicuota % 1 == 0 ? item.alicuota : (item.alicuota).toFixed(2) }} %
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              color="info"
              title="Ver"
              class="mr-2"
              small
              @click="ver(item)"
            >
              fas fa-eye
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No se encontraron financiaciones para la cuota seleccionada
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal que muestra las financiaciones de una cuota
 */
export default {
  data () {
    return {
      load: false,
      financiaciones: [],
      headers: [
        { text: 'Descripción', value: 'descripcion' },
        { text: 'Alicuota', value: 'alicuota', align: 'end' },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: false, filterable: false },
      ],
    }
  },
  props: {
    value: Boolean,
    cuota_codigo: {
      type: Number,
      default: null
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    dialog (val) {
      if (val) {
        this.get_financiaciones()
      }
    }
  },
  methods: {
    async get_financiaciones () {
      this.financiaciones = []
      if (this.cuota_codigo) {
        this.load = true
        await this.$store.dispatch('cuotas/get_financiaciones', this.cuota_codigo)
          .then((res) => {
            this.financiaciones = res.data
          })
          .catch((error) => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.load = false 
      }
    },
    ver (item) {
      const routeData = this.$router.resolve({ path: `/financiacion/${item.id}` })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>