<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="open_dialog(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-text="nombre_corto"
                    item-value="id"
                    tabindex="1"
                    :items="empresas"
                    hide-details
                    autofocus
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="7" class="py-1">
                  Tarjetas
                  <v-autocomplete
                    v-model="filtro.tarjetas"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="tarjetas"
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Nombre
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Plan
                  <v-text-field
                    v-model.trim="filtro.plan"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2" class="d-flex justify-center align-center py-1">
                  <v-switch
                    v-model="filtro.inhabilitada"
                    label="Inhabilitada"
                    tabindex="1"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-data-table
        class="cebra elevation-2 mt-2"
        :headers="headers"
        :items="cuotas"
        :loading="load"
        :search="search"
        :sort-by="['tarjeta_nombre', 'nombre']"
        multi-sort
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="open_dialog(item)"
          >
            fas fa-eye
          </v-icon>
          <v-icon
            color="purple"
            title="Financiaciones"
            class="mr-2"
            small
            @click="cuota_codigo = item.codigo; dialog_financiaciones = true"
          >
            fas fa-money-bill-alt
          </v-icon>
          <BtnConfirmar
            :icono="item.inhabilitada ? 'fas fa-check' : 'fas fa-ban'"
            :color="item.inhabilitada ? 'success' : 'error'"
            :title="item.inhabilitada ? 'Habilitar' : 'Inhabilitar'"
            :texto="item.inhabilitada
              ? `¿Desea habilitar <strong>${item.nombre}</strong> de <strong>${item.tarjeta_nombre}</strong>?`
              : `¿Desea inhabilitar <strong>${item.nombre}</strong> de <strong>${item.tarjeta_nombre}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="item.inhabilitada ? habilitar(item) : inhabilitar(item)"
          />
          <v-icon
            color="light-green"
            title="Costos financieros"
            class="ml-2"
            small
            @click="cuota = item; dialog_costos = true"
          >
            fas fa-donate
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- componentes -->
    <Cuota
      v-model="dialog"
      :p_nueva="nueva"
      :p_cuota="cuota"
      :p_tarjetas="tarjetas"
      @editar="editar"
    />
    <ModalFinanciaciones
      v-model="dialog_financiaciones"
      :cuota_codigo="cuota_codigo"
    />
    <ModalCostosFinancieros
      v-model="dialog_costos"
      :p_cuota="cuota"
    />
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import SearchDataTable from '../../components/util/SearchDataTable'
import Cuota from '../../components/auditoria/Cuota'
import ModalFinanciaciones from '../../components/auditoria/ModalFinanciaciones'
import ModalCostosFinancieros from '../../components/auditoria/ModalCostosFinancieros'

export default {
  data () {
    return {
      panel: 0,
      search: '',
      load: false,
      nueva: false,
      dialog: false,
      dialog_financiaciones: false,
      dialog_costos: false,
      cuota_codigo: null,
      cuotas: [],
      headers: [
        { text: 'Nombre', value: 'nombre' },
        { text: 'Tarjeta', value: 'tarjeta_nombre' },
        { text: 'Cuotas', value: 'cuotas', align: 'end' },
        { text: 'Cuota interna', value: 'cuota_interna', align: 'end' },
        { text: 'Plan', value: 'plan' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false },
      ],
      cuota: {},
      filtro: {
        empresa: null,
        tarjetas: null,
        nombre: '',
        plan: '',
        inhabilitada: 0
      }
    }
  },
  async created () {
    // si tiene solo 1 empresa la selecciona
    if (this.una_empresa) {
      this.filtro.empresa = this.empresas[0].id
    }
    this.$store.state.loading = true
    this.$store.dispatch('tarjetas/get_tarjetas_habilitadas')
    this.$store.dispatch('cheques/get_tipos')
    this.$store.dispatch('cheques/get_formatos')
    this.$store.dispatch('cheques/get_banco_tipos_mov')
    this.$store.state.loading = false
  },
  computed: {
    ...mapGetters(['una_empresa']),
    ...mapState(['empresas']),
    ...mapState('tarjetas', ['tarjetas'])
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    SearchDataTable,
    Cuota,
    ModalFinanciaciones,
    ModalCostosFinancieros
},
  methods: {
    async buscar () {
      if (this.filtro.empresa) {
        this.load = true
        this.search = ''
        this.cuotas = []
        await this.$store.dispatch('cuotas/get_cuotas', this.filtro)
          .then((res) => {
            this.cuotas = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.load = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar una empresa',
          color: 'warning'
        })
      }
    },
    async habilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('cuotas/habilitar', item.codigo)
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          // "elimina" la cuota de la lista
          this.quitar(item)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async inhabilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('cuotas/inhabilitar', item.codigo)
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          // "elimina" la cuota de la lista
          this.quitar(item)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    editar (item, bancos) {
      // actualiza la cuota en la lista
      let cuota = this.cuotas.find(cuo => cuo.codigo == item.codigo)
      Object.assign(cuota, item)
      cuota.bancos = [...bancos]
      // si la inhabilito la quita de la lista
      if (cuota.inhabilitada == 1) {
        this.quitar(cuota)
      }
    },
    quitar (item) {
      const index = this.cuotas.indexOf(item)
      this.cuotas.splice(index, 1)
    },
    open_dialog (item) {
      if (item) {
        this.nueva = false
        this.cuota = JSON.parse(JSON.stringify(item))
      } else {
        this.nueva = true
        this.cuota = {}
      }
      this.dialog = true
    },
    limpiar () {
      this.filtro = {
        empresa: null,
        tarjetas: null,
        nombre: '',
        plan: '',
        inhabilitada: 0
      }
    }
  }
}
</script>